const sentryErrors = [
  'ChunkLoadError',
  'Error: Page not found',
  'ReferenceError: Chartboost',
  'Error: Cannot find static asset',
  "Unexpected token '<'",
  'ReportingObserver [deprecation]:',
  'NetworkError:',
  "TypeError: Cannot read properties of undefined (reading 'setScreenSize')",
  "TypeError: Cannot read properties of undefined (reading 'audioVolumeChange')",
  "TypeError: Cannot read properties of undefined (reading 'setMaxSize')",
  "TypeError: Cannot read properties of undefined (reading 'setDefaultPosition')",
  "TypeError: Cannot read properties of undefined (reading 'setCurrentPosition')",
  "TypeError: Cannot read properties of undefined (reading 'fireChangeEvent')",
  "TypeError: Cannot read properties of undefined (reading 'fireReadyEvent')",
  "TypeError: Cannot read properties of undefined (reading 'setIsViewable')",
  "TypeError: null is not an object (evaluating 'instance.htmlElement.contentWindow.postMessage')",
  'ReferenceError: fbq is not defined',
  "Cannot read properties of null (reading 'postMessage')",
];

export default sentryErrors;
